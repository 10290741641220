import React from 'react';
import { graphql, Link } from 'gatsby';
import get from 'lodash/get';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { AiFillInstagram as InstagramIcon } from 'react-icons/ai';
import {
  FaGlobe as GlobeIcon,
  FaTwitter as TwitterIcon,
  FaFacebookSquare as FacebookIcon,
  FaYoutube as YoutubeIcon
} from 'react-icons/fa';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Footer from '../components/footer';
import { isAndAbove } from '../util';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const ArtistsTemplate = props => {
  const artist = get(props, 'data.contentfulZeoMusicArtist');

  const breakpoints = useBreakpoint();

  const paddingTop = isAndAbove('md', breakpoints) ? 86 : 66;

  const bioJson = get(artist, 'bio.json');
  const bioRenderOptions = {
    renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        console.log(children);
        return <p className="my-6 text-lg leading-relaxed">{children}</p>;
      },
      [INLINES.HYPERLINK]: (node, children) => {
        const href = node.data.uri;
        return href.includes('http') ? (
          <a className="text-blue" href={href} target="_blank">
            {children}
          </a>
        ) : (
          <Link className="text-blue" to={href}>
            {children}
          </Link>
        );
      }
    }
  };

  return (
    <Layout location={props.location} breakpoints={breakpoints}>
      <SEO title={artist.name} />
      <div className="bg-gray-900" style={{ paddingTop: paddingTop }}>
        <div className="bg-gray-100">
          <div className="max-w-4xl mx-auto px-6 py-12 sm:py-16">
            <img
              className="w-full object-cover overflow-hidden rounded-xl"
              style={{ maxHeight: '32rem' }}
              src={artist.image.file.url}
              alt={artist.image.title}
            />
            <div className="mt-4 sm:mt-6 text-3xl sm:text-4xl leading-10 font-extrabold text-blue-darker">
              {artist.name}
            </div>
            <div className="flex text-gray-500 mt-2">
              {artist.website && (
                <a href={artist.website} target="_blank">
                  <GlobeIcon size={25} className="mr-2" />
                </a>
              )}
              {artist.instagram && (
                <a href={artist.instagram} target="_blank">
                  <InstagramIcon size={25} className="mr-2" />
                </a>
              )}
              {artist.facebook && (
                <a href={artist.facebook} target="_blank">
                  <FacebookIcon size={25} className="mr-2" />
                </a>
              )}
              {artist.twitter && (
                <a href={artist.twitter} target="_blank">
                  <TwitterIcon size={25} className="mr-2" />
                </a>
              )}
              {artist.youTube && (
                <a href={artist.youTube} target="_blank">
                  <YoutubeIcon size={25} className="mr-2" />
                </a>
              )}
            </div>
            <div className="my-4 max-w-4xl mx-auto">{documentToReactComponents(bioJson, bioRenderOptions)}</div>
          </div>
        </div>
      </div>
      <Footer breakpoints={breakpoints} />
    </Layout>
  );
};

export default ArtistsTemplate;

export const pageQuery = graphql`
  query ArtistBySlug($slug: String!) {
    contentfulZeoMusicArtist(slug: { eq: $slug }) {
      name
      image {
        title
        file {
          url
        }
      }
      website
      instagram
      facebook
      twitter
      youTube
      bio {
        json
      }
    }
  }
`;
